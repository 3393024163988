.about-section {
    padding: 30px;
}

.about-title {
    margin: 20px 0 20px 0;
    display: flex;
    justify-content: center;
    text-transform: capitalize;
    font-size: var(--section-font-size);
    color: var(--primary-blue);
    font-weight: bold;
}

.about-content {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 30px;
}

.about-image {
    justify-self: center;
}

.spinning-image {
    transition: transform 1s ease;
    transform: rotateY(360deg);
}

/* .about-chips-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
} */

.about-text-chip {
    max-width: 600px;
    background-color: white;
}

@media (max-width: 600px) {
    .about-chips-section {
        display: grid;
        grid-gap: 10px;
    }
}