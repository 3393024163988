.hobbies-section {
    padding: 30px;
}

.hobbies-title {
    margin: 20px 0 20px 0;
    display: flex;
    justify-content: center;
    text-transform: capitalize;
    font-size: var(--section-font-size);
    color: var(--primary-blue);
    font-weight: bold;
}

.hobbies-content {
    align-items: center;
    justify-content: center;
    display: grid;
    grid-gap: 30px;
}