.cv-description-chips-default {
    width: 904px;
    height: 313px;
    background-color: var(--tertiary-blue);
    border-radius: 15px;
    align-content: center;
    justify-content: center;
}

.cv-description-default-container {
    display: grid;
    align-content: center;
    justify-content: center;
}

#cv-description-default-p {
    justify-self: center;
}

.cv-description-default-content {
    font-size: var(--title-font-size);
    text-align: center;
    color: black;
    display: flex;
    align-content: center;
    justify-content: center;
    padding: 15px;
}

.cv-description-chips {
    width: 904px;
    height: 313px;
    background-color: var(--tertiary-blue);
    border-radius: 15px;
    justify-self: center;
    align-self: center;
    display: flex;
}

.cv-description-container {
    align-content: center;
    justify-content: center;
    display: flex;
}

.cv-description-content {
    display: grid;
    align-content: center;
    padding-left: 20px;
    padding-right: 20px;
}

.cv-description-content > * {
    height: fit-content;
    margin: 2px;
}

#duration {
    font-style: italic;
    color: gray;
}

.cv-description-chips-image {
    align-self: center;
    max-height: 120px;
    max-width: 120px;
    margin: 60px 0 60px 66px;

}

.cv-description-chips-text {
    margin: 35px;
    align-content: center;
}

@media (max-width: 924px) {
    .cv-description-chips-default {
        display: none;
    }

    .cv-description-chips {
        display: none;
    }
}