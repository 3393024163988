.hero-section {
    height: calc(100vh - 100px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.hero-info {
    display: flex;
    flex-wrap: wrap-reverse;
    align-items: center;
    justify-content: center;
    width: 50%;
}

.hero-text {
    font-size: var(--title-font-size);
    text-align: center;
}

.hero-text p {
    margin: 5px;
}


.hero-text-2 {
    display: flex;
    justify-content: center;
}

.hero-elmo {
    width: auto;
}

.hero-text {
    margin-right: 10px;
}

#tsparticles canvas{
    z-index: -999;
    position: absolute;
    top: 0;
    left: 0;
}
