.contact-section {
    padding: 30px;
}

.contact-title {
    margin: 20px 0 20px 0;
    display: flex;
    justify-content: center;
    text-transform: capitalize;
    font-size: var(--section-font-size);
    color: var(--primary-blue);
    font-weight: bold;
}

.contact-content {
    display: grid;
    justify-items: center;
    align-items: center;
}

.contact-text {
    font-size: var(--sub-title-font-size);
    text-align: center;
    color: black;
    padding: 15px;
}

.contact-icons {
    display: flex;
}

.contact-icons img {
    padding: 35px;
}   

.contact-icon-row1,
.contact-icon-row2 {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.hippo-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.hippo-icon {
    z-index: 2;
}

.hippo-icon:hover + .honk-text {
    z-index: 1;
    transition: 0.4s;
    position: absolute;
    margin-top: 8px;
    margin-left: 95px;
    transform: rotate(0deg);
    padding: 0px !important;
}

.honk-text {
    position: absolute;
    z-index: 1;
    transition: 0.4s;
    margin-top: 32px;
    margin-left: 95px;
    padding: 0px !important;
    transform: rotate(90deg);
    transform-origin: left top;
}