.navbar-div {
  height: 10vh;
  max-height: 100px;
}

.navbar {
    display: block;
    width: 100%;
    height: 10vh;
    max-height: 100px;
    position: fixed;
    backdrop-filter: blur(3px);
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 10;
}

@media (max-width: 924px){
  .navbar {
    display: none;
  }
}

.elmo-logo {
    float: left;
    margin-top: 20px;
    margin-left: 30px;
    cursor: pointer;
}

.nav-stroke ul li.menu-item,
.nav-stroke ul li.menu-item:after,
.nav-stroke ul li.menu-item:before {
  transition: all .5s;
}

.nav-stroke ul li.menu-item{
  position: relative;
}

.nav-stroke ul li.menu-item:after{
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 0%;
  content: '.';
  color: transparent;
  background: var(--secondary-blue);
  height: 2px;
}

.nav-stroke ul li.menu-item:hover:after {
  width: 100%;
}

.nav-stroke ul {
    font-size: var(--sub-title-font-size);
    float: right;
    list-style: none;
    display: flex;
    align-items: flex-start;
    gap: 30px;
    padding-left: 0;
    padding-right: 20px;
}

.nav-stroke li a {
    color: black;
    text-decoration: none;
    display: flex;
    width: 170px;
    height: 50px;
    justify-content: center;
    align-items: center;
}

.nav-stroke ul li.menu-item a:hover {
    color: #02CCFE;
    cursor: pointer;
}

.nav-stroke ul li.contact-button a {
    border: 3px solid #02CCFE;
    border-radius: 5px;
    color: #02CCFE;
    text-decoration: none;
    transition: all .5s;
}

.nav-stroke ul li.contact-button a:hover {
    border-radius: 5px;
    background-color: #02CCFE;
    color: white;
    cursor: pointer;
    transition: all .5s;
}