.cv-section {
    padding: 30px;
}

.cv-title {
    margin: 20px 0 20px 0;
    display: flex;
    justify-content: center;
    text-transform: capitalize;
    font-size: var(--section-font-size);
    color: var(--primary-blue);
    font-weight: bold;
}

@media (max-width: 924px) {
    .timeline-section {
        display: none;
    }

    #timeline-selected-title {
        display: none;
    }
}

.timeline-section {
    padding-top: 160px;
    padding-bottom: 180px;
}

.timeline-line-div {
    justify-content: center;
    display: flex;
    position: relative;
}

.timeline-line {
    position: relative;
    display: flex;
    align-items: center;
    top: -5px;
}

.timeline-line-solid {
    position: relative;
    width: 50%;
    max-width: 835px;
    height: 7px;
    background-color: black;
}

.timeline-line-dashed {
    position: relative;
    width: 10%;
    max-width: 167px;
    height: 0px;
    border-bottom: 7px dotted #555;
}

.timeline-dot-1 {
    position: absolute;
    left: 74px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: var(--primary-blue);
    z-index: 3;
}

.timeline-dot-2 {
    position: relative;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: var(--primary-blue);
    z-index: 3;
}

.timeline-dot-3 {
    position: relative;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: var(--primary-blue);
    z-index: 3;
}

.timeline-dot-4 {
    position: relative;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: var(--primary-blue);
    z-index: 3;
}

.timeline-dot-5 {
    position: relative;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: var(--primary-blue);
    z-index: 3;
}

.timeline-dot-6 {
    position: relative;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: var(--primary-blue);
    z-index: 3;
}

.timeline-dot-6:hover {
    cursor: pointer;
}

.timeline-dot-inner-up {
    position: relative;
    height: 75px;
    border-right: 4px dashed #555;
    z-index: 2;
    transform: translate(-35%, -100%);
}

.timeline-dot-inner-down {
    position: relative;
    height: 75px;
    border-right: 4px dashed #555;
    z-index: 2;
    transform: translate(-35%, 17.5%);
}

.timeline-image-up {
    cursor: pointer;
    position: relative;
    max-width: 65px;
    max-height: 65px;
    z-index: 4;
    transform: translate(-37.5%, -325%);
    border-radius: 5%;
}

.timeline-image-down {
    cursor: pointer;
    position: relative;
    max-width: 65px;
    max-height: 65px;
    z-index: 4;
    transform: translate(-37.5%, 15%);
    border-radius: 5%;
}

.timeline-image-selected {
    outline: 3px solid var(--primary-blue);
}

.timeline-selected-title {
    font-size: var(--sub-title-font-size);
    padding-bottom: 25px;
    display: flex;
    justify-content: center;
}

.timeline-selected-text {
    display: grid;
    align-content: center;
    justify-content: center;
}

.cv-footer {
    display: flex;
    justify-content: center;
    padding: 30px 30px 0 30px;
}

.cv-footer-text a {
    font-size: var(--text-font-size);
    text-decoration: none;
    color: black;
}

.cv-accordion-section {
    justify-items: center;
    display: grid;
}

@media (min-width: 925px) {
    .cv-accordion-section {
      display: none;
    }
}