@media (min-width: 925px){
    .hamburger-menu-container {
      display: none;
    }
}

.hamburger-menu-container {
    position: fixed;
    float: right;
    width: 100%;
    height: 100px;
    font-size: var(--text-font-size);
    backdrop-filter: blur(3px);
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 100;
}
  
.elmo-logo {
    float: left;
    margin-top: 20px;
    margin-left: 30px;
    cursor: pointer;
}

#menu-hamburger{
	width: 35px;
	height: 30px;
	margin: 40px 20px 0px 20px;
	cursor: pointer;
    float: right;
}

.bar{
	height: 5px;
	width: 100%;
	background-color: var(--primary-blue);
	display: block;
	border-radius: 5px;
	transition: 0.3s ease;
}

#bar1{
	transform: translateY(-4px);
}

#bar3{
	transform: translateY(4px);
}

.nav li a{
	color: #fff;
	text-decoration: none;
}

.nav li a:hover{
	font-weight: bold;
}

.nav li{
	list-style: none;
	padding: 16px 0;
}

.nav{
	padding-top: 80px;
	margin: 0 20px;
	transition: 0.3s ease;
	display: none;
    text-align: right;
}

.menu-bg, #menu-bar{
	top: 0;
	right: 0;
	position: absolute;
}

.menu-bg{
	z-index: 1;
	width: 0;
	height: 0;
	margin: 30px 20px 0px 20px;
	background: radial-gradient(circle, var(--primary-blue), var(--secondary-blue));
    /* background: radial-gradient(circle, var(--secondary-blue), var(--primary-blue)); */
	border-radius: 50%;
	transition: 0.5s ease;
}

#menu-bar{
	z-index: 2;
    font-size: var(--sub-title-font-size);
}

.change-bg{
	width: 600px;
	height: 600px;
	transform: translate(60%, -25%);
}

.change .bar{
	background-color: white;
}
.change #bar1{
	transform: translateY(4px) rotateZ(-45deg);
}
.change #bar3{
	transform: translateY(-6px) rotate(45deg);
}
.change #bar2{
	opacity: 0;
}

.change{
	display: block;
}