.text-chip {
    max-width: 904px;
    background-color: var(--tertiary-blue);
    border-radius: 15px;
    display: grid;
}

.text-content {
    font-size: var(--text-font-size);
    color: black;
    align-content: center;
    justify-content: center;
    padding: 15px;
}

#text-subtitle {
    font-weight: bold;
}