/* Container styles */
.content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider-wrapper {
  font-size: var(--title-font-size);
  text-transform: uppercase;
  display: flex;
  justify-content: center;
}

.slider {
  height: 40px;
  padding-left: 5px;
  font-weight: bold;
  overflow: hidden; /* One of the importing things for slide effect */
}

.slider div {
  height: 40px;
  padding-left: 10px;
  color: var(--primary-blue);
  text-align: left;
  margin-bottom: 40px;
  box-sizing: border-box;
  white-space: nowrap;
}

.slider-text-1 {
  background: #ffffff;
  animation: slide 5s linear infinite;
}

.slider-text-2 {
  background: #ffffff;
}

.slider-text-3 {
  background: #ffffff;
}

@keyframes slide {
  0% {
    margin-top: -240px;
  }
  5% {
    /* For stopping effect */
    margin-top: -160px;
  }
  33% {
    margin-top: -160px;
  }
  38% {
    /* For stopping effect */
    margin-top: -80px;
  }
  66% {
    margin-top: -80px;
  }
  71% {
    /* For stopping effect */
    margin-top: 0px;
  }
  100% {
    margin-top: 0px;
  }
}
